import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import PageHeader from "../../components/pageHeader"
import SEO from "../../components/seo"

import axios from 'axios'

import ScrollAnimation from 'react-animate-on-scroll';
import Blog1 from '../../components/images/blog1.js'
import ImageNatalie from '../../components/images/imageNatalie.js'


class DemarquezVous extends React.Component {

  constructor(props){
    super(props)
    this.state = {
        year: new Date().getFullYear(),
    }
  }

  componentDidMount() {
      this.setState({year: new Date().getFullYear()})
  }

    render(){
    return(
      <Layout>
        <SEO title="Démarquez-vous avec un CV qui a de l’impact! | Gestion Mieux-Être" />
        <Blog1 />
        <div className="main-wrapper2">
            <div className="tleft">
                <br /><br /><br /><br />
                <h2>Démarquez-vous avec un CV qui a de l’impact ! </h2>
                <br /><br />
                <p>Le marché de l'emploi {this.state.year} est en pleine ébullition. N’est-ce pas le bon moment pour revoir votre CV?  Démarquez-vous avec un CV qui se distingue par sa clarté et surtout, qui vous rend pleinement justice.  Laissez-moi vous aider à vous présenter de manière convaincante.   </p>
                <p>Aujourd’hui, nous explorerons la section « Mon profil » de votre CV {this.state.year}.  Bien qu’elle occupe moins de 15 % de l’espace disponible de votre CV, cette section est tellement fondamentale … mais bien trop sous-estimée.  C’est pourtant ici que vous établissez votre premier vrai contact avec votre recruteur.  Décrivez-vous de façon concise et convaincante.  Cet argumentaire punch se compose d’une dizaine de courtes phrases sur un maximum de 6 - 8 lignes.  </p>
                <p>Votre profil percutant s’articule essentiellement autour de 4 thématiques : </p>
                <ol class="bold olpad green">
                    <li>1.	D’abord, placez un titre professionnel qui vous décrit (ex : gestionnaire opérationnel dans l’industrie des transports – ou - professionnel dans le domaine du numérique).  Vous utilisez ici 4-5 mots qui exposent l’essentiel de ce que vous voulez que le recruteur comprenne et retienne de vous.  </li>
                    <li>2.	Ensuite, mettez en évidence les compétences qui vous démarquent.  Bien sûr, ne perdez pas d’espace à énumérer des compétences qui sont évidentes. Nommez les compétences qui vous distinguent vraiment.</li>
                    <li>3.	Et maintenant, allez-y d’une ou deux phrases qui résument vos réalisations.  C’est le moment d’illustrer cette touche personnelle qui vous a permis d’atteindre vos succès.  Attention, il ne s’agit pas ici d’une répétition mécanique de votre CV. Le recruteur lira les détails plus tard.</li>
                    <li>4.	Et finalement, parlez-nous de votre prochaine étape professionnelle.  Aidez le recruteur à bien comprendre, en une phrase, ce à quoi vous aspirez maintenant.  C’est l’endroit idéal pour donner une couleur à votre candidature. Mais attention, ne perdez pas ici votre crédibilité en soulevant des éléments farfelus.</li>
                </ol>
                <p>Entre nous, il vous faudra passer beaucoup, mais beaucoup de temps afin de rédiger votre section « Mon profil ».  Néanmoins, investissez temps et énergie.  Je vous le promets, ça fera toute la différence!</p>
                <p>Lors d’un prochain billet mensuel, j’explorerai avec vous le cœur de votre CV {this.state.year}, vos réalisations et succès.  C’est un grand plaisir de vous soutenir !</p>
                <div className="owner-wrapper2">
                    <ImageNatalie />
                    <div className="owner-details">
                        <b>Natalie Bertrand</b><br />
                        M.Sc. – CRHA
                    </div>
                </div>
                <br /><br />
            </div>
        </div>
      </Layout>
    )
  }

}

export default DemarquezVous
